/* Modal overlay covering the whole page */
.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.75); /* Semi-transparent background */
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
  }
  
  /* Modal content box */
  .modal-content {
    background: white;
    /* width: 80%;
    height: 80%; */
    max-width: 900px;
    border-radius: 8px;
    position: relative;
    padding: 20px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
    display: flex;
    flex-direction: column;
    overflow-y: auto; /* Adds scrolling inside the modal if content exceeds */

  }
  
  /* Close button for the modal */
  .close-button {
    position: absolute;
    top: 10px;
    right: 15px;
    background: none;
    border: none;
    font-size: 2rem;
    cursor: pointer;
  }
  
  /* Modal body (content area) */
  .modal-body {
    flex: 1;
    overflow-y: auto;
  }
  
  /* Button to trigger modal */
  .open-modal-button {
    padding: 10px 20px;
    font-size: 16px;
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
  }
  