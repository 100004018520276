.product-list-page {
  display: flex;
  flex-direction: column;
  font-family: "Be Vietnam Pro", system-ui;
}

.top-image {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #f0f0f0eb;
}


.top-image img {
  max-width: 100%;
  height: auto;
}

.main-content {
  display: flex;
  height: 950px; /* Remaining 75% for the main content */
  padding: 50px;
  background-color: #f5f5f5;
}

.product-names {
  background-color: #343a40;
  padding: 20px;
}

.product-names ul {
  list-style-type: none;
  padding: 10px;

}

.product-names li {
  margin-bottom: 5px;
}

.product-names button {
  width: 100%;
  padding: 10px 20px;
  cursor: pointer;
  background:none;
  border: none;
  text-align: center;
  color: var(--global-white-color);
  font-family: "Be Vietnam Pro", system-ui;
  font-size: 18px;
  border-bottom: 1px solid #71757a;
  font-weight: 500;   
  border-radius: 5px;  
  padding: 10px 10px;
}



.product-details {
  width: 75%;
  height: 800px; /* Remaining 75% for the main content */
  padding: 20px 100px;
  overflow-y: auto; /* Scrollable section */
  display: flex;
  flex-direction: column;
  scroll-behavior: smooth;
}

.product-card-productpage {
  margin-bottom: 30px;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap:50px;
}

.product-image {
  width: 250px;
  height: auto;
  object-fit: cover;
  margin-right: 20px;
  /* box-shadow: rgba(1, 1, 1, 0.3) 0px -50px 36px -28px inset; */
  /* border-bottom: 10px solid var(--global-bg-color); */
  padding: 15px;
}

.product-info-list {
  display: flex;
  flex-direction: column;
  gap: 20px;
  margin-bottom: 75px;
  margin-left: 30px;
}

.product-info-list p {
  margin-top: 10px;
  font-size: 1em;
  color: #555;
  text-align: justify;
}

.product-color-box{
  display: flex;
  gap: 30px;
}
.box1{
  padding:20px 55px;
  margin: 20px 0px 50px 0px;
  background-color: var(--global-bg-color);
}

.box2{
  padding:20px 55px;
  margin: 20px 0px 50px 0px;
  background-color: orange;
}
.product-quantity{
  text-align: left;
  /* margin-top: 10px; */
  font-size: 18px;
  font-family: "Be Vietnam Pro", system-ui;
  font-weight: 500;
}
.product-names-title{
  font-family: "Be Vietnam Pro", system-ui;
  font-size: 22px;
  /* margin-left: 130px; */
  margin-left: 130px;
  margin-bottom: 20px;
  margin-top: 20px;
  color: var(--global-white-color);
  font-weight: 500;
  text-decoration: underline;
  text-underline-offset: 6px;
  text-decoration-color: var(--global-bg-color);
}

.product-icon{
  display: flex;
  font-size: 30px;
  gap: 20px;
}
.icon-border{
  padding: 5px 30px 0px 30px;
  color: var(--global-white-color);
  background-color: var(--global-footer-color);
  align-items: center;
}

.custom-list li {
  margin-bottom: 10px;
  font-size: 16px;
  margin-left: 15px;
}

.product-title-list{
  font-size: 26px;
  text-decoration: underline;
  text-underline-offset: 10px;
  text-decoration-color: var(--global-bg-color);
  font-weight: 500;
  text-transform: uppercase;
}

.ourproduct-img-content {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: var(--global-footer-color);
  text-align: center;
  z-index: 1; /* Ensure content stays above the ourproduct-img */
}

.ourproduct-img-content p {
  font-size: 32px;
  color: var(--global-footer-color);
  font-weight: 500;
  margin-bottom: 400px;
}


.our-Products-desc {
  padding: 50px 100px;
  position: relative;
}

.products-desc {
  padding: 0px 100px;
  text-align: justify;
  position: relative;
}

.our-Products-title {
  text-align: center;
  font-size: 32px;
  margin-bottom: 25px;
  font-weight: 500;
  position: relative;
}


/* Scrollbar for modern browsers */
::-webkit-scrollbar {
  width: 8px;
}

::-webkit-scrollbar-thumb {
  background: #f5f5f5; /* Thumb color */
  border-radius: 0px;
}

::-webkit-scrollbar-thumb:hover {
  background: #f5f5f5; /* Hover effect */
}

::-webkit-scrollbar-track {
  background: #f5f5f5; /* Track color */
}

@media screen and (max-width:900px) {
  .product-list-page{
    width: 100%;
  }
  .our-Products-desc{
    padding: 30px 30px;
  }
  .our-Products-title{
    font-size: 20px;
    margin-bottom:10px;
  }
  .products-desc{
    font-size: 14px;
    padding: 0px;
    color:#707070;
  }
  .main-content{
    flex-direction: column;
    row-gap: 40px;
  }
  .product-names-title{
    text-align: center;
    margin:0px;
    font-size: 20px;
    }
  .product-details{
    width: 100%;
    padding: 0px 0px
  }
  .product-info-list p{
    font-size: 14px;
  }
  .custom-list li{
    font-size: 14px;
  }
  .product-quantity{
    font-size: 14px;
  }
  .product-names li{
    font-size: 14px;
  }
  .main-content{
    padding: 0px;
  }
  .product-names button{
    font-size: 14px;
  }
  .product-card-productpage{
    display: block;
  }
  .product-title-list{
    font-size: 18px;
    text-underline-offset:4px
  }
  .product-image{
  margin-left: 60px;
  }
}

