img {
  width: 100%;
  height: auto;
}
.homeSlider{
  margin-bottom: -5px;
}

.homeSlider .slick-dots {
  padding: 30px;
}

.slider-wrapper {
  position: relative; /* Make the wrapper relative to position the content over it */
}

/* Adjust the z-index of the images */
.homeSlider img {
  z-index: 0; /* Ensure the slider stays in the background */
}

@media screen and (max-width: 600px){
  .homeSlider {
    width: 100% ;
    margin-top: 0px;
  }
}
