/* OurVisionMission.css */


.container-visionmission {
  display: flex;
  gap: 80px;
    max-width: 1400px;
    margin: 0 auto;
    padding: 40px 0;
}

.content-visionmission {
  flex:1;
  padding:20px;
}

.vision-content{
  text-align: justify;
}

.vision-content div{
  text-align: center;
  font-family: "Be Vietnam Pro", system-ui;
  font-size: 32px;
  padding: 10px;
}


.vision-content p{
  font-family: "Be Vietnam Pro", system-ui;
  font-size: 16px;
  font-weight: 400;
  color: #707070;
  line-height: 24px;
  padding: 10px 0px;
}



.mission-content{
  text-align: justify;
}

.mission-content div{
  text-align: center;
  font-family: "Be Vietnam Pro", system-ui;
  font-size: 32px;
  padding: 10px;
}


.mission-content p{
  font-family: "Be Vietnam Pro", system-ui;
  font-size: 16px;
  font-weight: 400;
  color: #707070;
  line-height: 24px;
  padding: 10px 0px;
}

@media screen and (max-width:900px){
  .container-visionmission {
    width: 100%;
    display: block;
  }
  .vision-content {
    padding-left: 0px;
  }
  .mission-content {
    padding-right: 0px;
  }
  .mission-content p {
    padding: 0px;
    font-size: 14px;
    line-height: 22px;

  }
  .content-visionmission {
    padding: 0px 40px;
  }
  .vision-content p {
    padding: 0px;
    font-size: 14px;
    line-height: 22px;
  }

  .vision-content div {
    padding: 10px;
    font-size: 20px;
  }
  .mission-content div{
    padding: 10px;
    font-size: 20px;
  }
}


