.dashboard-container {
    display: flex;
    margin-top:95px;
    font-family: "Be Vietnam Pro", system-ui;
  }
  
  .sidebar {
    width: 200px;
    background-color: #f4f4f4;
    padding: 20px;
    box-shadow: 2px 0 5px rgba(0, 0, 0, 0.1);
  }
  
  .sidebar-title {
    margin-bottom: 20px;
    font-size: 18px;
    color: #333;
  }
  
  .sidebar-menu {
    list-style: none;
    padding: 0;
  }
  
  .menu-item {
    padding: 10px;
    margin-bottom: 10px;
    cursor: pointer;
    border-radius: 4px;
    transition: background-color 0.3s;
  }
  
  .menu-item.active,
  .menu-item:hover {
    background-color: var(--global-bg-color);
    color: white;
  }
  
  .content {
    flex: 1;
    padding: 20px;
    overflow-y: auto;
  }
  
  