@import url('https://fonts.googleapis.com/css2?family=Raleway:ital,wght@0,100..900;1,100..900&display=swap');
:root { 
  --primary-color: #ec1b1b;
  --text-color: #101010;
  --bg-color: #f5f5f5;
  --white-color: #fff;
  --shadow-color: rgba(0, 0, 0, 0.1);
  font-family: "Raleway", sans-serif;
}

.header {
  position: sticky;
  top: 0;
  width: 100%;
  background-color: var(--white-color);
  z-index: 1000;
  box-shadow: 0 2px 10px var(--shadow-color);
  padding: 20px 10px;
  font-family: "Raleway", sans-serif;
}

.header-content {
	display: flex;
	justify-content: space-between;
	align-items: center;
	max-width: 1400px;
	margin: 0 auto;
}

/* Logo Box */
.logo-box img {
  width: 250px;
}

/* Email Box */
.email-box {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.email-details p {
  margin: 0;
  font-size: 15px;
  color: #575757;
  line-height: 1.8;
}

.hamburger-icon {
  display: none; /* Hide on desktop view */
  font-size: 14px;
  cursor: pointer;
}

/* Menu Links */
.menu-box ul {
  list-style-type: none;
  display: flex;
  gap: 50px;
  margin: 0;
  padding: 0;
}

.menu-box ul li a {
  color: var(--text-color);
  text-decoration: none;
  font-size: 17px;
  font-weight: 500;
  position: relative;
  transition: color 0.3s ease-in-out;
}

.menu-box ul li a:hover:before {
  width: 100%;
}

/* Responsive Styles */
@media screen and (max-width: 991px) {
	  .header-content {
			display: inline;
			padding-right: 0;
			text-align: center;
	  }
		.header {
			padding: 20px;
		}
	  .email-box {
		align-items: center;
	  }

	  .email-details p{
		font-size: 14px;
	  }

	  .menu-box, .email-box {
		display: none;
	  }
	  .hamburger-icon {
		display: block;        
		position: absolute;
		top: 31px;
		right: 32px;
	  }
	.menu-box.open, .email-box.open {
		display: flex;
		flex-direction: column;
		align-items: center;
		background-color: var(--white-color);
		position: absolute;
		left: 0;
		right: 0;
		width: 100%;
		padding: 20px;
		border-top: 1px solid #ccc;
        border-bottom: 1px solid #ccc;
	}
	.menu-box.open {
	 	top: 170px;	
		box-shadow: 0 4px 6px #05050557;
	}
	.email-box.open {
     	top: 85px;
	}

  .menu-box ul {
    flex-direction: column;
    gap: 20px;
  }

  .menu-box ul li a {
    font-size: 16px;
  }

}
