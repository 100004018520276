.contact-us-container {
  display: flex;
  font-family: "Be Vietnam Pro", system-ui;
  text-align: center;
}

.contact-form-column{
  flex: 1;
  background-color: #f5f5f5;
  padding: 70px; 
}

.address-column-title{
  color: #fff;
  font-family: "Be Vietnam Pro", system-ui;
  font-size: 32px;
  border: 1px solid #fff;
  padding: 5px;
}

.address-column-main{
  margin-top: 100px;
}

.address-column-icon{
  font-size: 32px;
  color: var(--global-bg-color);
}
.address-column-content-title{
  display: flex;
  font-size: 20px;
  font-family: "Be Vietnam Pro", system-ui;
  text-decoration: underline;
  text-decoration-color: var(--global-bg-color);
  text-underline-offset: 6px;
}

.address-column-content{
  margin-top: 10px;
}

.address-column-content p{
  margin-top: 20px;
}

.address-column{
flex: 1;
padding: 40px;
background-color: black;
}

.address-column-child{
  display: flex;
  color: #fff;
  padding: 20px;
  text-align: left;
  gap: 20px;
  align-items: center;
  margin-left:177px;
}
.contact-title {
  font-size: 32px;
  color: var(--global-footer-color);
  margin: 20px 150px 20px 150px;
  font-family: "Be Vietnam Pro", system-ui;
  padding: 5px 10px;
  font-weight: 500;
}

.contact-form {
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 60px;
  border: none;
  background-color: #ffffff;
  /* border-radius: 10px; */
  
}

input, textarea {
  padding: 10px;
  font-size: 16px;
  border: 1px solid #ccc;
  border-radius: 4px;
  transition: border-color 0.3s ease;
  font-family: "Be Vietnam Pro", system-ui;

}

select{
  font-family: "Be Vietnam Pro", system-ui;
}

textarea {
  resize: vertical; /* Allows users to resize the textarea vertically */
  min-height: 100px; /* Ensure textarea has a minimum height */
}

input.input-error, textarea.input-error {
  border-color: #e74c3c; /* Highlight error fields */

}

.error-message {
  color: #e74c3c;
  font-size: 14px;
  margin-top: 5px;
}

.submit-button-contact{
  margin-left: 160px;
  margin-right: 160px;
}
.mapcontainer{
  height: 50vh;
  width: 100%;
  background-color: #f5f5f5;
  padding: 10px;

}
.infowindow{
  padding: 0px 10px 40px 10px;
}
.mapoffice{
  font-size: 18px;
  font-weight: 500;
}
.contact-success-alert{
  text-align: center;
}
.contact-button{
    display: flex;
    justify-content: center;
    align-items: center;
}
.contact-success-btn{
  background-color: green;
  padding: 10px 20px;
  border: none;
  border-radius: 10px;
  margin: 10px;
  color: #f5f5f5;
}

/* Media Query for Small Screens (Mobile) */
@media screen and (max-width: 900px) {
  .contact-us-container {
    width: 100%;
    display: block;
  }
  .mapcontainer {
    width: 100%;
  }
  .address-column-content p{
    font-size: 14px;
  }
  .address-column-title{
    font-size: 20px;
  }
  .address-column-content-title{
    font-size: 20px;
  }
  .contact-form-column{
    padding: 10px;
  }
  .contact-form{
    padding:40px;
  }
  .contact-title{
    margin: 0px;
    padding: 0px;
    font-size: 20px;
  }
  .submit-button-contact{
    margin: 0px;
  }
  .address-column-child{
    margin: 0px;
  }
  .address-column-main{
    margin: 0px;
  }
  .address-column-icon{
    font-size: 20px;
  }
  .modal-content{
    margin: 30px;
  }

}




