  /* Button Styling */
  .card-button {
    padding: 10px 15px;
    font-size: 14px;
    color: var(--global-footer-color);
    background-color: var(--global-white-color);
    /* border: 2px solid  var(--global-bg-color); */
    border: none;
    border-radius: 4px;
    cursor: pointer;
    /* transition: background-color 0.3s ease; */
    margin-top: 20px;
  }
  
  .card-button:hover {
    background-color: var(--global-white-color);
  }
  .vision_mission {
    display: flex;
  }