.product-list-container {
    /* max-width: 900px; */
    margin: 0 auto;
    padding: 20px;
    background-color: #ffffff;
    border-radius: 8px;
    /* box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1); */
  }
  
  .product-list-title {
    margin-bottom: 20px;
    font-size: 24px;
    color: #333;
    padding: 10px;
    margin-left: 450px;
  }
  
  .product-table {
    width: 100%;
    border-collapse: collapse;
    text-align: left;
    margin-top: 10px;
  }
  
  .product-table th,
  .product-table td {
    padding: 12px 15px;
    border-bottom: 1px solid #ddd;
    color: #333;
  }
  
  .product-table th {
    background-color: #f0f0f0;
    font-weight: bold;
  }
  
  .product-table tr:nth-child(even) {
    background-color: #f9f9f9;
  }
  
  .action-buttons {
    display: flex;
    gap: 10px;
  }
  
  .edit-button,
  .delete-button {
    padding: 8px 12px;
    font-size: 14px;
    color: #fff;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .edit-button {
    background-color: #007bff;
  }
  
  .edit-button:hover {
    background-color: #0056b3;
  }
  
  .delete-button {
    background-color: #e74c3c;
  }
  
  .delete-button:hover {
    background-color: #c0392b;
  }

  .norecords{
    text-align: center;
    font-size: 18px;
  }
  