/* Import slick-carousel CSS in your main CSS file or component file */
@import "~slick-carousel/slick/slick.css";
@import "~slick-carousel/slick/slick-theme.css";

.product-slider {
  padding: 50px 50px 50px 50px;
  position: relative; /* Ensure arrows are positioned relative to this container */
  color: var(--global-footer-color);
  font-family: "Be Vietnam Pro", system-ui;
  /* background-color: #f9f9f9; */
  background-image: url('../../../assets/productgallery_weserve_background.jpg');
  background-size: cover; /* Increase the width to 150%, keep height automatic */
  background-position: center;
  background-repeat:no-repeat;/* Prevent the image from repeating */
}

.product-slider::before{
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.3);
     /* Black shade with 50% opacity */
  }

.product-slider p {
  text-align: center;
  margin: 0px 580px 20px 580px;
  font-family: "Be Vietnam Pro", system-ui;
  font-size: 32px;
  padding: 5px;
}

.ourproduct-box {
  background-color: var(--bg-color);
  padding: 10px 20px;
  border-radius: 10px;
  box-shadow: 0 2px 5px var(--shadow-color);
}

/* Container for the slider */
.slider-container {
  position: relative;
}

/* Slider styles */
.product-slider .slick-slider {
  position: relative;
  padding-left:80px;
  margin-top: 20px;
}

/* Slick slider track styles */
.slick-track {
  display: flex;
}

/* Card styles */
.product-card {
  width: 60% !important;
  background: none;
  /* box-shadow: rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px; */
  text-align: center;
  padding: 10px;
  margin: 10px 10px; /* Reduced margin for better fit */
  flex: 0 0 auto; /* Ensure cards don’t shrink */
  /* border: 2px solid var(--global-footer-color); */
  border-radius: 12px;
}

.product-card img {
  width: 100%;
  height: auto;
  /* border-bottom: 1px solid #ddd; */
  /* border-radius: 12px; */
}

.product-info {
  padding: 10px;
}

.product-info h3 {
  margin: 10px 0 5px;
  color: #ffffff;
  font-family: sans-serif;
}

.product-info p {
  margin: 0;
  color: #333;
}

/* Arrow styles */
.slick-arrow {
  /* background-color: #bcb8b8f9; */
  border: none;
  cursor: pointer;
  border-radius: 50%;
  z-index: 1000;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  /* background-color: var(--global-bg-color); */

}

.slick-prev {
  left: -2px; /* Adjust position of left arrow */
  display: none;
}

.slick-next {
  right: 10px; /* Adjust position of right arrow */
}
.slick-arrow:hover{
  background-color: #bcb8b8f9;
}

/* Hide dots */
.product-slider .slick-dots {
  display: none !important; /* Ensure dots are hidden */
}

/* Responsive adjustments */
@media (max-width: 1024px) {
  .product-card {
    width: 100%; /* Full width on smaller screens */
    margin: 0 10px; /* Adjust margin */
  }
}

@media (max-width: 900px) {
  .product-slider{
    width: 100%;
    height: 30vh;
    padding: 0px;
  }
  .slick-prev{
    visibility: hidden;
  }
  .slick-next{
    visibility: hidden;
  }
  .product-slider .slick-slider{
    padding: 0px;
  }
  .product-card{
    padding: 0px;
  }
  .product-slider p{
    margin: auto;
    font-size: 20px;
  }

}
