.industries-we-serve {
  padding: 50px 100px;
  background: #f4f4f4; /* Light background for contrast */
  text-align: center;
  background-image: url('../../../assets/productgallery_weserve_background.jpg');
  background-size: cover; /* Increase the width to 150%, keep height automatic */
  background-position: center;
  background-repeat: no-repeat; /* Prevent the image from repeating */
  position: relative; /* To ensure pseudo-elements stay within this section */
}

.firstspan {
  font-size: 32px;
  color: #333;
  font-family: "Be Vietnam Pro", system-ui;
}

.industries-list {
  display: grid;
  grid-template-columns: repeat(4, 1fr); /* Creates 4 columns */
  row-gap: 70px; /* Adds space between the cards */
  column-gap: 20px;
  margin-top: 50px;
}

.industry-card {
  background: white;
  border-radius: 12px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); /* Slightly stronger shadow for cards */
  padding: 20px;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.industry-card:hover {
  transform: translateY(-5px); /* Subtle lift effect on hover */
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.3); /* Stronger shadow on hover */
}

.industry-icon {
  font-size: 40px;
  color: var(--global-bg-color); /* Unique color for icons */
  margin-bottom: 15px;
}

.secoundspan {
  margin-bottom: 10px;
  font-size: 20px;
  color: #333;
  font-weight: 500;
}

.industry-card p {
  font-size: 16px;
  color: #707070;
  font-family: "Be Vietnam Pro", system-ui;

}


@media screen and (max-width: 900px) {
  .industries-we-serve{
    width: 100%;
    padding: 40px;
  }
  .industries-list {
    display: flex;
    flex-direction: column;
    padding: 5px;
  }
  .industry-card{
    padding: 20px;
  }
  .secoundspan {
    font-size: 20px;
  }
  .firstspan{
    font-size: 22px;
  }
  .industry-card p {
    font-size: 14px;
    line-height: 22px;
    margin-top: 20px;
  }

}
