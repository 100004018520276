.about-us-container {
  width: 100%;
}


.top-section {
  width: 100%;
  height: 200px;
  background-image: url('../../assets/title_bg.jpg');
  background-size: contain;
  background-repeat: repeat;
  position: relative; /* Make the section a positioning context */
}

.content-overlay {
  position: absolute; /* Overlay content */
  top: 50%; /* Vertically center the content */
  left: 50%; /* Horizontally center the content */
  transform: translate(-50%, -50%); /* Adjust position to the center */
  color: white; /* Adjust text color based on your design */
  text-align: center; /* Center text within the content */
  font-size: 32px;
  color: #101010;
  font-family: "Be Vietnam Pro", system-ui;
  font-weight: 500;
}


/* .top-image-aboutus {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #f0f0f0eb;
} */

.middle-section,
.bottom-section {
  display: flex;
  align-items: center;
}

.middle-section {
  background-color: #f9f9f9;
  padding: 20px 40px;
}

.middle-section-image {
  flex: 1;
}

.bottom-section-image {
  flex: 1;
  padding: 20px;
}

.middle-section-content,
.bottom-section-content {
  flex: 1;
  padding: 10px;
}

.bottom-section-content-sub{
  width: 80%;
  margin: 0 auto;
}

.middle-image {
  width: 100%;
  height: 400px;
  object-fit: cover;
}

.about-title {
  font-size: 32px;
  text-align: center;
  color: #101010;
  font-family: "Be Vietnam Pro", system-ui;
  margin-left: 150px;
  padding: 5px;
  font-weight: 500;
}


.middle-section-content p {
  font-size: 16px;
  line-height: 1.6;
  padding: 0px 80px;
  text-align: justify;
  font-family: "Be Vietnam Pro", system-ui;
  margin-top: 20px;
  color: #707070;
}

.bottom-section {
  background-color: #101010;
}

.bottom-section-content {
  display: flex;
  flex-direction: column;

}

.ourvalues {
  font-size: 32px;
  text-align: left;
  color: aliceblue;
  text-decoration: underline;
  text-decoration-color: var(--global-bg-color);
  text-underline-offset: 6px;
  margin-left: 145px;
  margin-bottom: 10px;
}

.section1,
.section2,
.section3,
.section4 {
  font-family: "Be Vietnam Pro", system-ui;
  font-size: 18px;
  padding: 15px 0px;
  color: aliceblue;
  border-radius: 10px;
}

.title {
  font-family: "Be Vietnam Pro", system-ui;
  font-size: 24px;
  display: flex;
  gap: 10px;
  align-items: center;
}

.session-content {
  font-family: "Be Vietnam Pro", system-ui;
  font-size: 16px;
  margin-top: 10px;
  /* border: 1px solid; */
  /* padding: 10px;
  border-radius: 10px; */
}

.bottom-image {
  width: 100%;
  height: 700px;
  object-fit: cover;
}

.iconforabouts {
  color: var(--global-bg-color);
}

.line-aboutus {
  font-size: 16px;
  font-weight: 200;
}

@media screen and (max-width:900px){
  .container-aboutus{
    width: 100%;
    height: auto;
    display: block;
    padding: 10px;
  }  
    .about-us-container{
      width: 100%;
    } 
    .top-section{
      background-repeat: no-repeat;
      background-size: cover;
    }
    .middle-section{
      display: block;
    } 
    .bottom-section{
      display: block;
    } 
    .middle-section-content p {
      font-size: 14px;
      padding: 0px;
    }
    .about-title{
      font-size: 20px;
      margin: 0 auto;
    }
    .title {
      font-size: 30px;
    }
    .session-content{
      font-size: 16px;
      line-height: 25px;
    }
    .middle-image{
      height: auto;
    }
    .bottom-image{
      height: auto;
    }
    .section1, .section2, .section3, .section4{
      padding: 10px;
      border: 1px solid #cccccc8a;
      margin-bottom:15px;
    }
    .ourvalues{
      font-size: 22px;
      text-align:center;
      margin: 0px;
      padding-bottom:20px;
    }
    .title{
      font-size: 18px;
    }
    .content-overlay{
      font-size: 20px;
    }
}
