.login-container {
    max-width: 400px;
    margin: 20px auto;
    padding: 20px;
    background-color: #ffffff;
    border-radius: 8px;
    box-shadow:var(--global-box-shadow-color);
    text-align: center;
  }
  
  .login-title {
    margin-bottom: 20px;
    font-size: 24px;
    color: #333;
  }
  
  .login-form {
    display: flex;
    flex-direction: column;
    gap: 10px;
  }
  
  input,select {
    padding: 10px;
    font-size: 16px;
    border: 1px solid #ccc;
    border-radius: 4px;
    transition: border-color 0.3s ease;
  }
  
  .input-error {
    border-color: #e74c3c;
  }
  
  .error-message {
    color: #e74c3c;
    font-size: 14px;
    margin-top: 5px;
  }
  
  .login-button {
    padding: 12px 20px;
    font-size: 16px;
    color: var(--global-white-color);
    background-color: var(--global-bg-color);
    border: none;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .login-button:hover {
    background-color: var(--global-hover-color)
  }
  
  .success-message {
    color: #28a745;
    font-size: 14px;
    margin-top: 10px;
  }
  