
/* Import Google Fonts */
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600&display=swap');
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

/* Define CSS variables for the header and footer colors */
:root {
  --global-bg-color: #ec1b1b; /* #0292c2 | #ec1b1b */
  --offer-bg-color: #060e0d;
  --global-white-color: #ffffff;
  --global-footer-color:#222222;
  --global-green-color:#02590b;
  --global-hover-color:#B22222;
  --global-background-color:#cccccc;
  --global-box-shadow-color:rgba(0, 0, 0, 0.25) 0px 0.0625em 0.0625em, rgba(0, 0, 0, 0.25) 0px 0.125em 0.5em, rgba(255, 255, 255, 0.1) 0px 0px 0px 1px inset;
} 

html, body {
  height: 100%;
}

.main {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.form-group {
  display: flex;
  flex-direction: column;
  text-align: left;
}

label {
  font-size: 16px;
  margin-bottom: 5px;
  /* Slightly increased margin for spacing */
  color: var(--global-green-color);
  font-weight: 500;
  /* Bold labels for better readability */
}


.submit-button {
  padding: 14px 20px;
  font-size: 16px;
  color: var(--global-white-color);
  background-color: var(--global-bg-color);
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease, transform 0.2s;
}

.submit-button:hover {
  background-color: var(--global-hover-color);
  transform: translateY(-2px);
  /* Slight lift effect on hover */
}

.titleWeight{
  font-weight: 500;
}
