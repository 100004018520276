.footer {
    background-color: #1b1b1b;
    color: #f1f1f1;
    padding: 40px 20px;
    position: relative;
    font-family: "Be Vietnam Pro", system-ui;
    text-align: left;
  }
  
  .footer-container {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 20px;
    max-width: 1200px;
    margin: 0 auto;
  }
  

  .footer-section div {
    color: #cccccc;
    font-size: 16px;
    font-family: "Be Vietnam Pro", system-ui;
  }
  
  .footer-section ul {
    list-style-type: none;
    padding: 0;
    margin: 0;
  }
  
  .footer-section ul li {
    margin-bottom: 10px;
    color: #cccccc;
    font-size: 16px;
    font-family: "Be Vietnam Pro", system-ui;
  }

  
  .our-products li:hover ,.usefull-links li:hover {
    color: var(--global-bg-color);
    cursor: pointer;
  }
  

  .footer-section a {
    text-decoration: none;
    color: inherit;
  }
  
  .footer-section a:hover {
    color: var(--global-bg-color);
  }
  
  /* Social media section */
  .social-icons {
    display: flex;
    gap: 15px;
  }
  
  .social-icons a {
    /* background-color: var(--global-bg-color); */
    padding: 10px;
    border-radius: 50%;
    display: inline-block;
    /* color: white; */
    transition: background-color 0.3s ease;
  }
  
  .social-icons a:hover {
    /* background-color: #ffffff; */
    color: var(--global-white-color);
  }
  
  .social-icons i {
    font-size: 20px;
  }
  
  .footer-bottom {
    text-align: center;
    margin-top: 20px;
    padding-top: 10px;
    border-top: 1px solid #444;
  }
  
  .footer-bottom p {
    font-size: 16px;
    color: #cccccc;
  }

  .contact-us p,.about-company p,.usefull-links p,.our-products p{
    text-decoration: underline;
    text-decoration-color: var(--global-bg-color);
    text-underline-offset: 6px; /* Adjust this value to move the line down */
    margin-bottom: 10px;
    font-size: 22px;
  }
  
  /* Responsive design */
  @media screen and (max-width: 900px) {
   .footer{
    width: 100%;
    font-size: 20px;
    line-height: 35px;
    padding: 0px;
   }
   .footer-container{
    display: flex;
    flex-direction: column;
    padding: 0px;
   }
   .footer-section div{
    font-size: 14px;
    line-height: 22px;
    text-align: justify;
   }
   .footer-section{
    border-bottom: 1px solid #cccccc45;
    padding: 10px 40px;
   }
   .social-icons {
    display: block;
   }
   .footer-bottom{
    border-top: 0px solid #444;
    padding-bottom: 10px;
   }
   .contact-us p,.about-company p,.usefull-links p,.our-products p{
    font-size: 20px;
  }
  .footer-section ul li {
    font-size: 14px;
    line-height: 22px;
  }
  .footer-bottom p {
    font-size: 14px;
  }
  .social-icons{
    text-align: center !important;
  }
  .footer-section.social-media{
    padding-top:0px;
    padding-bottom: 9px;
  }

  }
  