/* AboutPage.css */
.about-page {
  font-family: 'Times New Roman', Times, serif;
  position: relative;
}

/* Split Section (Image and Text) */
.split-section {
  display: flex;
  align-items: center;
  padding: 50px;
  gap: 80px;
}

.split-content {
  flex: 1;
}

.aboutus-btn{
  font-size: 16px;
  border-radius: 5px;
  padding: 8px 15px;
  background: transparent;
  border-width: 2px;
  border-style: solid;
  border-color: #ec6504;
  transition: all 0.3s ease-in-out;
  text-transform: uppercase;
  letter-spacing: 1px;
  text-decoration: none;
  margin: auto;
  display: block;
  width: 160px;
  z-index: 0;
  cursor: pointer;
}

.alearnMore{
  text-decoration: none;
  color: #ec6504;
  font-family: "Be Vietnam Pro", system-ui;
  font-weight: 600;
}

.split-content h2 {
  font-size: 36px;
  margin-bottom: 20px;
  text-align: center;
  /* border: 1px solid #f07c31; */
  border-radius: 10px;
  color: #f07c31;
}

.split-content p {
  font-size: 18px;
  line-height: 1.6;
  text-align: justify;
}

.split-image {
  flex: 1;
}

.split-image img {
  width: 100%;
  height: auto;
  border-radius: 10px;
}


.container-aboutus {
  display: flex;
  height: 400px; /* Example height */
  width: 100%;   /* Full width of the container */
    overflow: hidden;
}

.content-aboutus {
  flex: 1;
  display: flex; /* Flexbox to center content */
  justify-content: center; /* Center horizontally */
  align-items: center;     /* Center vertically */
}


.image {
  flex: 1;
  display: flex; /* Flexbox to center content */
  justify-content: center; /* Center horizontally */
    overflow-clip-margin: content-box;
    overflow: clip;
	    margin: auto;
}

.order-content{
	    width: 85%;
  text-align: justify;
}

.order-content-title{
  text-align: center;	
  font-family: "Be Vietnam Pro", system-ui;
  font-size: 32px;
  padding: 5px;
}
.order-content button{
  text-align: center;
}

.order-content-paragraph{
  font-family: "Be Vietnam Pro", system-ui;
  font-size: 16px;
  font-weight: 400;
  color: #707070;
  line-height: 24px;
    margin: 23px 0;
}



/* Responsive Design */
@media screen and (max-width: 900px) {
	.container-aboutus {
    width: 100%;
    display: block;
	}
  .order-content-title{
    font-size: 20px;
  }
  .split-section {
    flex-direction: column;
  }
  .order-content-paragraph{
    font-size: 14px;
    line-height: 1.5;
  }
  .order-content{
    text-align: justify;
  }
  .order-img {
    padding: 10px;
  }
  .aboutus-btn{
    font-size: 12px;
  }
}
