.product-entry-container {
  max-width: 700px; /* Slightly increase width for a medium size */
  margin: 10px auto;
  padding: 30px;
  background-color: var(--global-white-color);
  border-radius: 8px;
  text-align: center;
}

.product-entry-title {
  margin-bottom: 20px;
  font-size: 24px;
  font-family: "Be Vietnam Pro", system-ui;
  padding: 50px;
}

.product-entry-form {
  display: flex;
  flex-direction: column;
  gap: 10px; 
  /* Increased gap for better spacing */
  margin-top: 20px;
}

input,
textarea {
  padding: 12px; /* Increased padding for a more comfortable input field */
  font-size: 16px;
  border: 1px solid #ccc;
  border-radius: 4px;
  outline: none;
  transition: border-color 0.3s ease;
}

input:focus,
textarea:focus {
  border-color: var(--global-bg-color); /* Highlight border on focus for better UX */
}

.image-preview {
  margin-top: 10px;
  max-width: 100%;
  height: auto; /* Maintain aspect ratio */
  border-radius: 4px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
}

.delete-button {
  background-color: red;
  color: white;
  border: none;
  padding: 5px;
  cursor: pointer;
  margin-top: 5px;
}

.preview-item {
  display: inline-block;
  position: relative;
  text-align: center;
}

.preview-container {
  display: flex;
  flex-wrap: wrap; /* Wraps items to the next line if they exceed the container width */
  gap: 10px; /* Adds space between items */
}

.preview-item {
  display: flex;
  flex-direction: column; /* Arranges items vertically */
  align-items: center; /* Centers items horizontally */
}

.image-preview {
  width: 100px;
  height: 100px;
  object-fit: cover; /* Ensures the image covers the area without distortion */
  margin-bottom: 5px; /* Adds space between the image and the button */
}

.delete-button {
  background-color: transparent; /* Transparent background */
  border: none; /* Removes border */
  cursor: pointer; /* Changes cursor to pointer */
  font-size: 24px; /* Adjusts icon size */
  color: red; /* Red color for the icon */
}

.alert {
  padding: 10px;
  margin: 10px 0;
  border-radius: 5px;
  text-align: center;
}

.alert-success {
  background-color: #4caf50;
  color: white;
}

.alert-error {
  background-color: #f44336;
  color: white;
}

.success-alert {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 25px 150px;
  background-color: #f0f9ff;
  border-radius: 10px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
}

.success-icon {
  color: #28a745; /* Green color for success */
  font-size: 4rem; /* Large icon */
  margin-bottom: 10px;
}

.failure-icon {
  color: #eb1717; /* Green color for success */
  font-size: 4rem; /* Large icon */
  margin-bottom: 10px;
}

.success-message {
  font-size: 1.5rem;
  font-weight: bold;
  color: #333;
}

.failure-message {
  font-size: 16px;
  font-weight: bold;
  color: #eb1717;
}

.success-btn {
  margin-top: 20px;
  padding: 10px 20px;
  background-color: #28a745;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 1rem;
  transition: background-color 0.3s ease;
  margin-left:150px;
}
.success-btn-update{
  margin-top: 20px;
  padding: 10px 20px;
  background-color: #28a745;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 1rem;
  transition: background-color 0.3s ease;
}

.failure-btn {
  margin-top: 20px;
  padding: 10px 20px;
  background-color: #eb1717;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 1rem;
  transition: background-color 0.3s ease;
}

.success-btn:hover {
  background-color: #218838;
}

.failure-btn:hover {
  background-color: #eb1717;
}
 .close-btn{
  padding: 10px;
 }